import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ScrollBtn from "../components/scroll-btn"
import Tech from "../components/content/technologies"
import Services from "../components/services"
import Projects from "../components/projects"
import AboutUs from "../components/about-us"
import Header from "../components/header"
import Footer from "../components/footer"

import { graphql } from "gatsby"
import { useEffect } from "react"
import { isBrowser } from "../utils/isBrowser"
import { scrollToSection } from "../utils/scrollToSection"

export const query = graphql`
  query GetHomepageEn {
    site {
      siteMetadata {
        title
      }
    }

    strapiContact(locale: { eq: "en" }) {
      locale
      localizations {
        data {
          attributes {
            slug
            locale
          }
        }
      }
      slug
    }

    strapiProjectsPage(locale: { eq: "en" }) {
      slug
    }

    strapiServicePage(locale: { eq: "en" }) {
      slug
    }

    strapiApproachPage(locale: { eq: "en" }) {
      slug
    }

    strapiAboutUsPage(locale: { eq: "en" }) {
      slug
    }

    allStrapiFooter(filter: { locale: { eq: "en" } }) {
      nodes {
        locale
        ContactText
        Heading
        RightsClaim
        SocialHeading
        Text
        Secondary {
          Link
          Text
        }
        Location {
          Heading
          Text {
            data {
              Text
            }
          }
        }
        Footer {
          Link
          Text
        }
      }
    }

    allStrapiHeader(filter: { locale: { eq: "en" } }) {
      nodes {
        locale
        MobileContactButtonText
        MobileContactHeading
        MobileContactText
        Links {
          Link
          Text
        }
        ContactText
      }
    }

    strapiHomepage(locale: { eq: "en" }) {
      locale
      localizations {
        data {
          attributes {
            locale
            slug
          }
        }
      }
      HeroTitle
      HeroRedText
      HeroText
      ApproachText {
        data {
          ApproachText
        }
      }
      ApproachSubtitle
      ApproachHeading
      AboutUs {
        Subtitle
        Slide {
          Description
          Heading
          DesktopImage {
            alternativeText
            url
            localFile {
              url
            }
          }
        }
      }
      Technology {
        Heading
        Image {
          TechImage {
            alternativeText
            url
            localFile {
              url
            }
          }
        }
      }
      Services {
        Box {
          Description
          Heading
          Image {
            alternativeText
            url
            localFile {
              url
            }
          }
        }
        Description {
          data {
            Description
          }
        }
        Heading
      }
      Projects {
        Slide {
          Client
          ClientName
          Description
          Heading
          Subtitle
          Color
          DesktopImage {
            alternativeText
            url
            localFile {
              url
            }
          }
          MobileImage {
            alternativeText
            url
            localFile {
              url
            }
          }
          project {
            slug
            Color
          }
        }
      }
    }

    strapiGeneralTranslation(locale: { eq: "en" }) {
      AllRightsReserved
      BackToBlog
      By
      Client
      CopiedToClipboard
      Keys
      ReadMore
      ScrollDownOrJustUse
      ViewProject
      CookieSettings
    }

    strapiGeneralSetting(locale: { eq: "en" }) {
      SocialLinks {
        Facebook
        LinkedIn
        Twitter
      }
    }
  }
`

const IndexPage = props => {
  const {
    AboutUs: AboutUsData,
    Projects: ProjectsData,
    Services: ServicesData,
    Technology: TechnologyData,
  } = props.data.strapiHomepage

  useEffect(() => {
    if (isBrowser()) {
      const sections = document.querySelectorAll(
        "#gatsby-focus-wrapper section"
      )
      let currentSection = 0

      window.addEventListener("keyup", initScroller, true)

      function initScroller(event) {
        event.preventDefault()
        event.stopPropagation()

        if (event.code === "ArrowDown") {
          if (currentSection < sections.length - 1)
            currentSection = currentSection + 1

          scrollToSection(sections, currentSection)
        }
        if (event.code === "ArrowUp") {
          if (currentSection > 0) currentSection = currentSection - 1

          scrollToSection(sections, currentSection)
        }
      }

      return function cleanup() {
        window.removeEventListener("keyup", initScroller, true)
      }
    }
  })

  return (
    <>
      <Header
        locale={"en"}
        siteTitle={props.data.site.siteMetadata?.title || `Title`}
        data={props.data.allStrapiHeader.nodes.find(
          header => header.locale === props.pageContext.langKey
        )}
        localizations={
          props.data.strapiHomepage.localizations.data[0].attributes
        }
        contactSlug={props.data.strapiContact.slug}
      />
      <Layout>
        <Seo 
		lang={"en"} 
		title={props.data.strapiHomepage.HeroTitle + " " + props.data.strapiHomepage.HeroRedText}
		description={props.data.strapiHomepage.HeroText}
		/>
        <section className="homepage-hero flex flex-col justify-center lg:min-h-[720px] px-6 relative">
          <div className="text-center my-8">
            <h1 className="xl-heading hero-title">
              <div className="relative">
                <div className="hero-circles-container">
                  <div className="circle circle--dashed circle--1 circle--1-dashed"></div>
                  {/* <div className="circle circle--full circle--1 circle--1-full">
              </div> */}
                  <div className="circle circle--dashed circle--2 circle--2-dashed"></div>
                  {/* <div className="circle circle--full circle--2 circle--2-full">
              </div> */}
                  <div className="circle circle--dashed circle--3 circle--3-dashed"></div>
                  {/* <div className="circle circle--full circle--3 circle--3-full">
            </div> */}
                  <div className="circle circle--dashed circle--4 circle--4-dashed"></div>
                  {/* <div className="circle circle--full circle--4 circle--4-full">
            </div> */}
                </div>
                <svg
                  viewBox="0 0 204 83"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M33.7961 39.4C38.5295 40.8 42.3628 43.2667 45.2961 46.8C48.2961 50.2667 49.7961 54.4667 49.7961 59.4C49.7961 66.6 47.3628 72.2333 42.4961 76.3C37.6961 80.3667 31.7961 82.4 24.7961 82.4C19.3295 82.4 14.4295 81.1667 10.0961 78.7C5.82948 76.1667 2.72948 72.4667 0.796143 67.6L12.5961 60.8C14.3295 66.2 18.3961 68.9 24.7961 68.9C28.3295 68.9 31.0628 68.0667 32.9961 66.4C34.9961 64.6667 35.9961 62.3333 35.9961 59.4C35.9961 56.5333 34.9961 54.2333 32.9961 52.5C31.0628 50.7667 28.3295 49.9 24.7961 49.9H21.7961L16.4961 41.9L30.2961 23.9H2.89613V11H46.8961V22.4L33.7961 39.4Z"
                    fill="#181C2F"
                  />
                  <path
                    d="M81.4883 33.7C88.755 33.9 94.655 36.2667 99.1883 40.8C103.722 45.2667 105.988 51 105.988 58C105.988 65.1333 103.622 71 98.8883 75.6C94.155 80.1333 88.055 82.4 80.5883 82.4C73.1217 82.4 67.0217 80.1333 62.2883 75.6C57.6217 71.0667 55.2883 65.2 55.2883 58C55.2883 52.6667 56.655 47.9667 59.3883 43.9L81.1883 11H96.9883L81.4883 33.7ZM72.2883 66.3C74.4883 68.3667 77.255 69.4 80.5883 69.4C83.9217 69.4 86.6883 68.3667 88.8883 66.3C91.155 64.2333 92.2883 61.4667 92.2883 58C92.2883 54.6 91.155 51.8667 88.8883 49.8C86.6883 47.7333 83.9217 46.7 80.5883 46.7C77.255 46.7 74.4883 47.7333 72.2883 49.8C70.0883 51.8 68.9883 54.5333 68.9883 58C68.9883 61.4667 70.0883 64.2333 72.2883 66.3Z"
                    fill="#181C2F"
                  />
                  <path
                    d="M139.762 82.4C131.028 82.4 124.162 79.1 119.162 72.5C114.228 65.8333 111.762 57 111.762 46C111.762 35 114.228 26.2 119.162 19.6C124.162 12.9333 131.028 9.6 139.762 9.6C148.562 9.6 155.428 12.9333 160.362 19.6C165.295 26.2 167.762 35 167.762 46C167.762 57 165.295 65.8333 160.362 72.5C155.428 79.1 148.562 82.4 139.762 82.4ZM129.162 63.1C131.562 66.9667 135.095 68.9 139.762 68.9C144.428 68.9 147.962 66.9333 150.362 63C152.828 59.0667 154.062 53.4 154.062 46C154.062 38.5333 152.828 32.8333 150.362 28.9C147.962 24.9667 144.428 23 139.762 23C135.095 23 131.562 24.9667 129.162 28.9C126.762 32.8333 125.562 38.5333 125.562 46C125.562 53.4667 126.762 59.1667 129.162 63.1Z"
                    fill="#181C2F"
                  />
                  <path
                    d="M186.37 33C181.57 33 177.57 31.4 174.37 28.2C171.17 24.9333 169.57 21.0333 169.57 16.5C169.57 11.9 171.17 8 174.37 4.8C177.57 1.6 181.57 0 186.37 0C191.17 0 195.17 1.6 198.37 4.8C201.57 8 203.17 11.9 203.17 16.5C203.17 21.1 201.57 25 198.37 28.2C195.236 31.4 191.236 33 186.37 33ZM182.07 20.8C183.203 21.9333 184.636 22.5 186.37 22.5C188.103 22.5 189.503 21.9333 190.57 20.8C191.703 19.6667 192.27 18.2333 192.27 16.5C192.27 14.7667 191.703 13.3333 190.57 12.2C189.503 11.0667 188.103 10.5 186.37 10.5C184.636 10.5 183.203 11.0667 182.07 12.2C181.003 13.3333 180.47 14.7667 180.47 16.5C180.47 18.2333 181.003 19.6667 182.07 20.8Z"
                    fill="#181C2F"
                  />
                  <circle
                    cx="81"
                    cy="58"
                    r="6"
                    fill="url(#paint0_linear_103_701)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_103_701"
                      x1="77.9067"
                      y1="51.9057"
                      x2="88.9026"
                      y2="57.8938"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#BD023A" />
                      <stop offset="1" stopColor="#FC4504" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <span className="">{props.data.strapiHomepage.HeroTitle}</span>
            </h1>
            <h2 className="xl-heading block text-transparent bg-clip-text bg-gradient-to-r from-maroon to-orange-red">
              {props.data.strapiHomepage.HeroRedText}
            </h2>
            <p className="intro-text max-w-intro mx-auto my-4">
              {props.data.strapiHomepage.HeroText}
            </p>
          </div>
          <div className="scrollButtonContainer">
            <Link to="#services" className="no-underline">
              <ScrollBtn
                beforeText={
                  props.data.strapiGeneralTranslation.ScrollDownOrJustUse
                }
                afterText={props.data.strapiGeneralTranslation.Keys}
              />
            </Link>
          </div>
        </section>

        <Services
          id={"leistungen"}
          approachId={"ansatz"}
          data={ServicesData}
          origin={props.location.origin}
          servicesSlug={props.data.strapiServicePage.slug}
          approachSlug={props.data.strapiApproachPage.slug}
          approachSubtitle={props.data.strapiHomepage.ApproachSubtitle}
          approachHeading={props.data.strapiHomepage.ApproachHeading}
          readMoreText={props.data.strapiGeneralTranslation.ReadMore}
          approachText={
            props.data.strapiHomepage.ApproachText.data.ApproachText
          }
        />

        {isBrowser && (
          <Projects
            id={"projekte"}
            origin={props.location.origin}
            slug={props.data.strapiProjectsPage.slug}
            viewProjectText={props.data.strapiGeneralTranslation.ViewProject}
            data={ProjectsData}
          />
        )}

        <AboutUs
          origin={props.location.origin}
          slug={props.data.strapiAboutUsPage.slug}
          readMoreText={props.data.strapiGeneralTranslation.ReadMore}
          id={"über-uns"}
          data={AboutUsData}
        />

        <Tech data={TechnologyData} />
      </Layout>
      <Footer
        contactSlug={props.data.strapiContact.slug}
        locale={"en"}
        cookieSettingsText={props.data.strapiGeneralTranslation.CookieSettings}
        socialLinks={props.data.strapiGeneralSetting.SocialLinks}
        data={props.data.allStrapiFooter.nodes.find(
          header => header.locale === props.pageContext.langKey
        )}
      />
    </>
  )
}

export default IndexPage
